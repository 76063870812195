import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private dataSuccessValue: any;
  private dataValue: any;
  private eventUrlValue: string | null = null;
  private loginEmailValue = new BehaviorSubject<string>(null);
  private loginDataValue = new BehaviorSubject<any>(null);

  constructor() {}

  private _isLoggedIn = false;

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  get dataSuccess(): any {
    return this.dataSuccessValue;
  }

  set dataSuccess(value: any) {
    this.dataSuccessValue = value;
  }

  get data(): any {
    return this.dataValue;
  }

  set data(value: any) {
    this.dataValue = value;
  }

  get eventUrl(): string {
    return this.eventUrlValue;
  }

  set eventUrl(value: string) {
    this.eventUrlValue = value;
  }

  setLoginEmail(value: string) {
    this.loginEmailValue.next(value);
  }

  getLoginEmail(): Observable<string> {
    return this.loginEmailValue.asObservable();
  }

  setLoginData(email: string, password: string) {
    this.loginDataValue.next({ email, password });
  }

  setLoginDataWithOutPassword(email: string) {
    this.loginDataValue.next({ email });
  }

  getLoginData() {
    return this.loginDataValue.asObservable();
  }

  clearLoginData() {
    this.loginDataValue.next(null);
  }
}
