import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private _errors: Set<string> = new Set<string>();
  private _errorSubject: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public error$: Observable<string | null> = this._errorSubject.asObservable();

  setError(value: string | null) {
    if (value) {
      if (!this._errors.has(value)) {
        this._errors.add(value);
        const updatedError = Array.from(this._errors).join('\n');
        this._errorSubject.next(updatedError);
      }
    } else {
      this._errors.clear();
      this._errorSubject.next(null);
    }
  }
}
