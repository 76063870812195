import { Injectable, ErrorHandler } from '@angular/core';
import { SafeAny } from '@src/types/safeAny';
import { IsPlatformService } from '@services/client/is-platform/is-platform.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private errorSubject = new Subject<void>();
  constructor(private isPlatformService: IsPlatformService) {
    this.errorSubject.subscribe(() => {
      this.hardReload();
    });
  }

  handleError(error: SafeAny): void {
    console.error('handleError', error);

    const chunkOrDynamicImportError =
      /(Failed to (fetch dynamically imported module|load module script): .+\.js)/;
    const moduleScriptError =
      /Failed to load module script:.*Expected a JavaScript module script.*but the server responded with a MIME type of "text\/html"/;
    const generalMimeError = /'[^']+' is not a valid JavaScript MIME type/;

    const errorMessage = error?.message || '';

    if (
      chunkOrDynamicImportError.test(errorMessage) ||
      moduleScriptError.test(errorMessage) ||
      generalMimeError.test(errorMessage)
    ) {
      console.error(
        'Dynamic module loading failed due to incorrect MIME type, reloading page',
      );

      if (this.isPlatformService.isBrowser()) {
        this.hardReload();
      }
    } else {
      console.error('Unhandled error:', error);
    }
  }

  listenToErrorEvents(): void {
    if (this.isPlatformService.isBrowser()) {
      if (typeof window.addEventListener === 'function') {
        // Use the standard error listener
        window.addEventListener('error', (event) => {
          console.error('Error event:', event);
          if (this.isModuleScriptError(event)) {
            this.handleModuleScriptError(event);
          } else {
            console.error('Unhandled error: ', event);
          }
        });
      } else {
        // For older browsers that don’t support addEventListener
        window.onerror = (message, source, lineno, colno, error) => {
          console.error(
            'Error captured using onerror (fallback): ',
            message,
            source,
            lineno,
            colno,
            error,
          );
          this.handleError(error);
          return true;
        };
      }
    }
  }

  private isModuleScriptError(event: SafeAny): boolean {
    const errorMessage = event?.message || '';
    return (
      (errorMessage.includes('Failed to load module script') &&
        errorMessage.includes('MIME type of "text/html"')) ||
      errorMessage.includes('is not a valid JavaScript MIME type"')
    );
  }

  private handleModuleScriptError(event: SafeAny): void {
    console.error('Module script error detected:', event);
    this.errorSubject.next();
  }

  private hardReload(): void {
    setTimeout(() => {
      window.location.href =
        window.location.origin +
        window.location.pathname +
        '?cache-bypass=' +
        Date.now();
    }, 0);
  }
}
