import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
  ArtistStoreActions,
  ArtistStoreSelectors,
  FanStoreActions,
  FanStoreSelectors,
  OrganizerStoreActions,
  OrganizerStoreSelectors,
  UserStoreSelectors,
} from '../../../store/root/client';

import { RootStoreState } from '@app/store/root';

export class ProfilesState {
  user: {
    logined: boolean;
    profile: any;
  };
  fan: {
    logined: boolean;
    profile: any;
  };
  artist: {
    logined: boolean;
    profile: any;
  };
  organizer: {
    logined: boolean;
    profile: any;
  };
}

@Injectable({ providedIn: 'root' })
export class ProfilesService {
  constructor(private store: Store<RootStoreState.State>) {}

  /**
   * Get profiles: Fan, Artist, Venue and Organizer auth state.
   */
  getProfileAuthorizationState(): Observable<any> {
    return combineLatest([
      this.store
        .select(FanStoreSelectors.getFanState)
        .pipe(
          distinctUntilChanged(
            (p, f) => p.logined === f.logined && p.loading === f.loading,
          ),
        ),
      this.store
        .select(ArtistStoreSelectors.getArtistState)
        .pipe(
          distinctUntilChanged(
            (p, f) => p.logined === f.logined && p.loading === f.loading,
          ),
        ),
      this.store
        .select(OrganizerStoreSelectors.getOrganizerState)
        .pipe(
          distinctUntilChanged(
            (p, f) => p.logined === f.logined && p.loading === f.loading,
          ),
        ),
    ]).pipe(
      map((result) => {
        if (result) {
          const fan = {
            logined: result[0].logined,
            loading: result[0].loading,
          };
          const artist = {
            logined: result[1].logined,
            loading: result[1].loading,
          };
          const organizer = {
            logined: result[2].logined,
            loading: result[2].loading,
          };
          return {
            fan,
            artist,
            organizer,
          };
        }
      }),
    );
  }

  getProfilesState(): Observable<ProfilesState> {
    return combineLatest([
      this.store
        .select(UserStoreSelectors.getUserState)
        .pipe(
          distinctUntilChanged(
            (p, f) => p.logined === f.logined && p.profile === f.profile,
          ),
        ),
      this.store
        .select(FanStoreSelectors.getFanState)
        .pipe(
          distinctUntilChanged(
            (p, f) => p.logined === f.logined && p.profile === f.profile,
          ),
        ),
      this.store
        .select(ArtistStoreSelectors.getArtistState)
        .pipe(
          distinctUntilChanged(
            (p, f) => p.logined === f.logined && p.profile === f.profile,
          ),
        ),
      this.store
        .select(OrganizerStoreSelectors.getOrganizerState)
        .pipe(
          distinctUntilChanged(
            (p, f) => p.logined === f.logined && p.profile === f.profile,
          ),
        ),
    ]).pipe(
      map((result) => {
        if (result) {
          if (result[0] && result[1] && result[2] && result[3]) {
            const user = {
              logined: result[0].logined,
              profile: result[0].profile,
            };
            const fan = {
              logined: result[1].logined,
              profile: result[1].profile,
            };
            const artist = {
              logined: result[2].logined,
              profile: result[2].profile,
            };
            const organizer = {
              logined: result[3].logined,
              profile: result[3].profile,
            };
            return {
              user,
              fan,
              artist,
              organizer,
            };
          }
        }
      }),
    );
  }

  clearProfilesData(): void {
    this.store.dispatch(new FanStoreActions.RemoveFan());
    this.store.dispatch(new ArtistStoreActions.RemoveProfile());
    this.store.dispatch(new OrganizerStoreActions.RemoveProfile());
  }
}
