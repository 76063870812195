import { Injectable, signal } from '@angular/core';
import { Profile } from '@models/client/profiles/create-profile-response.model';
import { defaultIfEmpty, EMPTY, firstValueFrom, timeout } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { SafeAny } from '@src/types/safeAny';
import { jwtDecode } from 'jwt-decode';
import { select, Store } from '@ngrx/store';
import {
  ProfileStoreActions,
  ProfileStoreSelectors,
} from '@store/root/client/profile';
import { EAccountTypes } from '@enums/account-types.enum';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RootStoreState } from '@store/root';
import { Router } from '@angular/router';
import { AccountService } from '@services/client/account/account.service';
import {
  NavbarStoreActions,
  SignInStoreActions,
  UserStoreActions,
} from '@store/root/client';
import { SocialAuthService } from '@services/client/social-auth/social-auth.service';
import { LocalStorageService } from '@services/client/local-storage/local-storage.service';
import { NavbarStatus } from '@store/root/client/navbar/navbar-status.enum';
import { ProfilesService } from '@services/client/profiles/profiles.service';
import { DataService } from '@services/client/data/data.service';

type AccountType = 'fan' | 'organizer' | 'artist' | 'uncreated';

@Injectable({
  providedIn: 'root',
})
export class GuardsServiceService {
  private profiles = signal<Profile[]>([]);
  private profilesState: Profile[];

  constructor(
    public afAuth: AngularFireAuth,
    private store: Store<RootStoreState.State>,
    private router: Router,
    private accountService: AccountService,
    private socialAuth: SocialAuthService,
    private localStorageService: LocalStorageService,
    private profilesService: ProfilesService,
    private dataService: DataService,
  ) {}

  setProfiles(profiles: Profile[]) {
    this.profiles.set(profiles);
  }

  findActiveProfileByType(profileType: string): boolean {
    const profiles = this.profiles();
    const profileIndex = profiles.findIndex(
      (profile) => profile.profileType === profileType,
    );
    if (profileIndex !== -1) return true;

    return false;
  }

  /**
   * Retrieves the user's token from AngularFireAuth.
   */
  async getToken(): Promise<string | null> {
    if (this.localStorageService.getUserToken()?.token) {
      return this.localStorageService.getUserToken()?.token;
    }

    const token: string | null = await firstValueFrom(
      this.afAuth.idToken.pipe(
        filter((token) => !!token),
        timeout(500),
        defaultIfEmpty(null),
        take(1),
        catchError(() => EMPTY),
      ),
    );

    if (token) {
      this.localStorageService.setUserToken({
        token,
      });
    }

    return token;
  }

  /**
   * Checks if the token is valid by decoding it.
   */
  isTokenValid(token: string): boolean {
    const parsedToken: SafeAny = jwtDecode(token);
    return !!parsedToken?.user_id;
  }

  /**
   * Handles invalid tokens by signing out the user and redirecting to the home page.
   */
  async handleInvalidToken(): Promise<boolean> {
    this.localStorageService.removeUserToken();
    await this.afAuth.signOut();
    return false;
  }

  /**
   * Processes profiles stored in the application state.
   */
  processProfiles(typeAccount: AccountType, navLink: string): void {
    this.store
      .pipe(
        select(ProfileStoreSelectors.getProfilesState),
        filter((state) => !!state?.profiles),
        timeout(1000),
        defaultIfEmpty(null),
        take(1),
        catchError(() => {
          return EMPTY;
        }),
      )
      .subscribe((state) => {
        this.profilesState = state.profiles;
        this.setProfiles(this.profilesState);
        const canActivateProfiles = this.findActiveProfileByType(typeAccount);
        if (!canActivateProfiles) {
          this.navigateToProfileCreation(typeAccount, navLink);
        }
      });
  }

  /**
   * Navigates the user to the profile creation page if a fan profile is not found.
   */
  navigateToProfileCreation(
    typeAccount: AccountType = 'uncreated',
    navLink: string,
  ): void {
    if (typeAccount === 'fan') {
      this.accountService.setAccountIsEditable(true);
      this.accountService.setAccountCreationInfo(EAccountTypes.Fan);
    } else if (typeAccount === 'organizer') {
      this.accountService.setAccountIsEditable(true);
      this.accountService.setAccountCreationInfo(EAccountTypes.Organizer);
    }
    this.router.navigate([navLink]).then();
  }

  /**
   * Handles errors by signing out the user and redirecting to the home page.
   */
  async handleError(navLink: string): Promise<boolean> {
    this.localStorageService.removeUserToken();
    await this.afAuth.signOut();
    this.router.navigate([navLink]).then();
    return false;
  }

  async handleErrorForLogin(): Promise<false> {
    this.router.navigate(['/']).then();
    return false;
  }

  handleDialogResult(link: string): boolean {
    this.logOut();
    this.handleError(link).then();
    return false;
  }

  logOut() {
    setTimeout(() => {
      this.localStorageService.removeUserToken();
      this.afAuth.signOut().then();
      this.socialAuth.signOut();
      this.dataService.data = null;
      this.store.dispatch(new ProfileStoreActions.RemoveActiveProfile());
      this.store.dispatch(new SignInStoreActions.SignOut());
      this.localStorageService.removeAllItems();
      this.store.dispatch(UserStoreActions.RemoveToken());
      this.store.dispatch(
        NavbarStoreActions.setStatus({ status: NavbarStatus.NonAuth }),
      );
      this.profilesService.clearProfilesData();
    }, 0);
  }
}
